/* Vendor imports */
import React from "react";
import { graphql, Link } from "gatsby";
/* App imports */
import SEO from "../components/seo";
import Footer from "../components/layout/footer";
import Config from "../../config";
import Utils from "../utils";
import Img from "gatsby-image";
import style from "./index.module.less";

const IndexPage = ({ data: { indieLogo, casualLogo } }) => (
  <React.Fragment>
    <SEO title="Home" description={Config.siteIndieDescr} path="" />

    <div className={style.container}>
      <div className={style.indie}>
        <Link to={Utils.resolvePageUrl(Config.pages.indie)}>
          <Img fluid={indieLogo.childImageSharp.fluid} />
          <h3>Indie Games</h3>
        </Link>
      </div>
      <div className={style.casual}>
        <Link to={Utils.resolvePageUrl(Config.pages.casual)}>
          <Img fluid={casualLogo.childImageSharp.fluid} />
          <h3>Casual Games</h3>
        </Link>
      </div>
    </div>
    <Footer />
  </React.Fragment>
);

export const query = graphql`
  {
    indieLogo: file(name: { eq: "bcl-logo-indie" }) {
      childImageSharp {
        fluid(maxHeight: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    casualLogo: file(name: { eq: "bcl-logo" }) {
      childImageSharp {
        fluid(maxHeight: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default IndexPage;
